@import "./../../variables.scss";

.CountBoxWrapper{
  padding: 10px 3px;
}

.CountBoxValue {
  display: inline-block;
  padding: 2px 3px 3px 3px;
  text-align: center;
  width: 15px;
  margin: 0 5px;
  font-weight: bold;
  font-size: 16px;
  color: var(--textColor);
}

.CountBoxButton {
  cursor: pointer;
  border-radius: 0;
  background: var(--grau2);
  vertical-align: sub;
}


