@import "./variables.scss";

@font-face{
  font-family:"Filson W04 Bold";
  src:url("./font/filsonpro/6147260/c2ae8ad4-42bb-431e-bb80-c83ba0a09c8e.woff2") format("woff2"),url("./font/filsonpro/6147260/acf68de4-ccc6-495f-89ec-bfedf84328e0.woff") format("woff");
}
@font-face{
  font-family:"Filson W04 Regular";
  src:url("./font/filsonpro/6147266/83ddd0c0-7d45-4303-bb0c-45a01faccfb7.woff2") format("woff2"),url("./font/filsonpro/6147266/77844849-fde7-4276-bc0e-d753e52ba3e8.woff") format("woff");
}
//@font-face {
//  font-family: "Typekit Font";
//  src:url("https://use.typekit.net/emz5nbc.css")
//}
@import url("https://p.typekit.net/p.css?s=1&k=emz5nbc&ht=tk&f=28423.28431.28433&a=5309578&app=typekit&e=css");

@font-face {
  font-family:"mr-eaves-xl-modern";
  src:url("https://use.typekit.net/af/85cac6/00000000000000007735b307/30/l?subset_id=2&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/85cac6/00000000000000007735b307/30/d?subset_id=2&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/85cac6/00000000000000007735b307/30/a?subset_id=2&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"mr-eaves-xl-modern";
  src:url("https://use.typekit.net/af/e35fa0/00000000000000007735b327/30/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/e35fa0/00000000000000007735b327/30/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/e35fa0/00000000000000007735b327/30/a?subset_id=2&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"mr-eaves-xl-modern";
  src:url("https://use.typekit.net/af/abe747/00000000000000007735b32e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/abe747/00000000000000007735b32e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/abe747/00000000000000007735b32e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

.tk-mr-eaves-xl-modern { font-family: "mr-eaves-xl-modern",sans-serif; }

body{
  margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  //font-family: 'Filson W04 Regular', sans-serif;
  font-family: 'mr-eaves-xl-modern', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--weiss);
  color: var(--schwarz);
  font-size: 16px;
}
.clearfix::after{
  content: "";
  clear: both;
  display: table;
}
code{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.logoBox img {
  display: block;
  margin: 0 0 0 auto;
}
.row {
  display: flex;
}
@for $col from 1 through 10 {
  .col-#{$col} {
    flex: $col;
  }
}

#root {

}
.fs-container {
  background-color: var(--grauBG);
  width: 1116px;
  margin: 0 auto 0 auto;
  padding: 0;
}
.fs-container.noBackground {
  background-color: transparent;
}

h2 {
  padding-left: 40px;
  color: var(--color2);
  font-size: 22px;
  font-weight: var(--headerFontWeight);
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.bg-white {
  background: var(--weiss);
}
.bold {
  font-weight: bold;
}
.displayInlineBlock {
  display: inline-block;
}
.navButtons{
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.modalButton,
.navButton{
  cursor: pointer;
  display: inline-block;
  width: 143px;
  height: 45px;
  line-height: 45px;
  color: var(--weiss);
  font-size: 16px;
  text-align: center;
  margin: 20px;
}
.modalButton.agree,
.navButton.next{
  background: var(--blueColor);
}
.modalButton.disagree,
.navButton.prev{
  background: var(--grau3);
}
.button{
  display: inline-block;
}
.addRoomWrapper .header,
.addFloorWrapper .header{
  padding: 10px 0 0 45px;
}
.addRoomWrapper .header span,
.addFloorWrapper .header span{
  padding-left: 5px;
  vertical-align: middle;
  color: var(--textColor);
  font-size: 16px;
  font-weight: bold;
}
.addRoomWrapper .button,
.addFloorWrapper .button{
  cursor: pointer;
}
.addRoomWrapper svg.MuiSvgIcon-root,
.addFloorWrapper svg.MuiSvgIcon-root{
  color: var(--color1);
  vertical-align: middle;
  font-size: 19px;
}
.customPlan {
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--textColor);
  font-weight: bold;
  font-size: 16px;
  .plusIcon {
    color: var(--blueColor);
    vertical-align: middle;
    font-size: 19px;
    margin-right: 5px;
  }
}
.openClose{
  cursor: pointer;
  display: inline-block;
  width: 25px;
  > svg {
    vertical-align: middle;
  }
}
.floorTitle{
  display: inline-block;
}
.floorBoxHeader{
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.floorWrapper{
  margin-top: 20px;
}
.floorRow.sortable-chosen{
  background: var(--grau1);
}
.addProjectListItem.active{
  background: var(--blueColor);
  color: var(--weiss);
  g,
  g * {
    fill: var(--weiss);
    stroke: transparent;
  }
}
.addProjectListItem{
  cursor: pointer;
  text-align: center;
  padding: 20px 5px;
  background: var(--weiss);
  color: var(--schwarz);
  g,
  g * {
    fill: var(--schwarz);
    stroke: transparent;
  }
}
.roomTitle{
  display: inline-block;
}
.roomRow {
  border-bottom: 1px solid var(--grau2);
}
.roomRow:hover{
  background: var(--grau2);
}
.roomRow.sortable-chosen{
  background: var(--grau1);
}
.secondBlock{
  margin-top: 70px;
  margin-bottom: 35px;
}
.secondBlock h2{
  margin-bottom: 0;
}
.secondBlock span{
  color: var(--color2);
  font-size: 22px;
  padding-left: 40px;
}
.fontSize22{
  font-size: 22px;
  color: var(--color2);
}
.fs-box-white-30-no-margin,
.fs-box-green-30,
.fs-box-white-30{
  padding: 30px;
}
.fs-box-white-30-no-margin,
.fs-box-white-30,
.fs-box-white{
  background: var(--weiss);
}
.fs-box-white-30,
.fs-box-white{
  margin: 40px 0;
}
.fs-box-green-30{
  background: var(--gruen1);
  margin: 40px 0;
}
input.inputText{
  margin-bottom: 20px;
  width: 100%;
  background: var(--grau1);
  color: var(--schwarz);
  font-size: 16px;
  padding: 3px;
  border: 0 none;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.header-block{
  padding: 40px;
}
.floorWrapper.sortable-chosen{
  background: var(--grau1);
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color: var(--color1)!important;
}
.MuiSwitch-colorSecondary.Mui-checked{
  color: var(--color1)!important;
}
.levelSelectBox{
  border: 1px solid var(--schwarz);
  padding: 15px;
  cursor: pointer;
}
.levelSelectBox:nth-child(2){
  margin: 0 45px;
}
.levelSelectBox .description{
  margin: 20px;
}
.levelSelectBox.active{
  border: 1px solid var(--blueColor);
  background: var(--blueColor);
  color: var(--weiss);
}
.equimentSelectComponent{
  padding: 0px 55px;
}
  .equimentBoxHeader{
    cursor: pointer;
    display: flex;
    align-items: center;
    .openClose {
      font-size: 22px;
      font-weight: bold;
      vertical-align: bottom;
    }
  .equimentBoxHeader-title{
    font-size: 32px;
    font-weight: normal;
    color: var(--captionColor);
    flex-grow: 1;
  }
}
.equimentBoxHeader-subtitle{
  padding-left: 25px;
  font-size: 14px;
  color: var(--color2);
}
.tableHeader{
  margin-top: 40px;
}
.optionCounter, .roomCounter {
  color: var(--schwarz);
  font-size: 36px;
  margin-bottom: 10px;
}
.optionCounterImage {
  max-width: 100%;
  max-height: 100%;
  height: 40px;
  color: var(--color2);
}
.optionName {
  font-size: 11px;
  margin-top: 5px;
  font-weight: bold;
}
.labeltext{
  padding-right: 20px;
}
.buttonSelectDesignForRoom{
  cursor: pointer;
  color: var(--color1);
}
.buttonResetDesignForRoom{
  cursor: pointer;
  color: #3c95b3;
}

.PrivateTabIndicator-colorPrimary-6 {
  background-color: var(--color1)!important;
}

.step5SelectWrapper {
  padding-top: 15px;
  #grouped-select-room{
    padding-right: 0;
    max-width: 100%;
  }
  .step5SelectItem {
    cursor: pointer;
    border: 1px solid var(--schwarz);
    padding: 10px;
    margin: 10px;
    text-align: center;
    max-width: 33%;
    background: var(--weiss);
    border-color: transparent;
    .MuiInput-input {
      color: var(--captionColor);
      font-family: "mr-eaves-xl-modern", sans-serif !important;
    }
    &.active{
      color: var(--weiss);
      background-color: var(--color1);
      border-color: var(--color1);
    }
    :hover :before,
    ::before{
      border: 0 none !important;
    }
  }
  button{
    margin: 10px;
  }
  button:first-child,
  .step5SelectItem:first-child{
    margin-left: 0;
  }
  button:last-child,
  .step5SelectItem:last-child{
    margin-right: 0;
  }
}
.systemWahlWrapper{
  width: 50%;
  margin: 0 auto;
}

.productList{
  .tableHeader{
    padding: 10px 0 0px 5px;
    border-top: 1px solid var(--grau2);
    font-style: italic;
  }
  .group{
    margin-bottom: 20px;
  }
  .groupHeader{
    color: var(--color2);
    font-weight: normal;
    font-size: 22px;
    padding: 15px 0;
  }
  .groupContent > .row{
    padding: 10px 0 10px 5px;
    border-top: 1px solid var(--grau2);
    &:last-child{
      border-bottom: 1px solid var(--grau2);
    }
    &:hover{
      background-color: var(--grau2);
    }
  }
}
.foerderbox{
  margin-bottom: 0
}
.foerderbox,
.preisbox{
  .row{
    margin-top: 30px;
  }
  .title{
    color: var(--color2);
    font-weight: bold;
    font-size: 22px;
  }
  .subtitle{
    color: var(--grau3);
    font-size: 12px;
  }
  .gesamtsumme{
    width: 75%;
    max-width: 320px;
    margin-right: 0;
    margin-left: auto;
    margin-top: 15px;
    .label{
      //display: inline-block;
      float: left;
      text-align: left;
    }
    .value{
      //display: inline-block;
      float: right;
      width: 150px;
      text-align: right;
    }
    .line2{
      clear: both;
      text-align: left;
    }
    .label,
    .value{
      color: var(--color2);
      font-weight: bold;
    }
    &.mitBEG{
      .label,
      .value{
        font-size: 22px;
      }
    }
    &.ohneBEG{
      .label,
      .value{
        font-size: 18px;
      }
    }
  }
}
.foerderForm{
  width: 60%;
  margin: 0 auto;
}
.headerMenu{
  float: left;
  a{
    color: var(--grau3);
    text-decoration: none;
  }
  a:hover{
    color: var(--schwarz);
    text-decoration: none;
  }
  .headerMenuItem{
    cursor: pointer;
    margin-left: 20px;
    font-weight: bold;
    color: var(--grau3);
    &:first-child{
      margin-left: 40px;
    }
  }
}

.exportButtons{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  button{
    margin: 0 5px;
    max-width: 270px;
    border-radius: 8px;
    line-height: 14px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.codeBoxFinish{
  font-size: 22px;
  margin: 0 auto;
  width: 56%;
  &.button {
    text-align: right;
    margin: -40px 0;
    width: 100%;
    .icon-to-page-bottom {
      &.only-hover { display: none; }
    }
    &:hover {
      .icon-to-page-bottom {
        display: none;
        &.only-hover { display: unset; }
      }
    }
  }
}
.button {
  .icon-to-page-bottom {
    &.only-hover { display: none; }
  }
  &:hover {
    color: var(--blueColor);
    .icon-to-page-bottom {
      display: none;
      &.only-hover { display: unset; }
    }
  }
  &.woText {
    .MuiButton-label {
      align-items: flex-end;
      flex-direction: column;
      margin-right: -10px;
    }
  }
}
.icon-to-page-bottom {
  margin-right: 5px;
}
.footer{
  color: var(--grau3);
  a{
    color: var(--grau3);
    text-decoration: none;
    padding: 0 10px;
    &:nth-child(n+2){
      border-left: 1px solid var(--grau3);
    }
  }
  a:hover{
    color: var(--schwarz);
    text-decoration: none;
  }

}
.box {
  width: 46%;
  padding: 15px;
  font-size: 16px;
  transition: all 3s;
  &.right {
    float: right;
  }
  &.blue {
    background: var(--blue);
    color: white;
    width: 45%;
    padding: 20px;
    h1, h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }
  &.darkblue {
    background: var(--darkblue);
    color: white;
    width: 45%;
    padding: 20px;
    h1, h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .start-buttons {

  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 25px;
    color: white;
    padding-left: 0;
  }
  a {
    width: 100%;
    text-align: center;
    display: block;
    cursor: pointer;
  }
  .inputBoxContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      display: flex;
      flex-grow: 0;
      margin-bottom: 0;
      align-content: center;
      align-items: center;
      height: fit-content;
    }
    input {
      flex-grow: 1;
      margin-right: 9.48px;
      padding: 8px 0
    }
    //button {
    //  background: transparent;
    //  border: 1px solid white;
    //  color: white;
    //  padding: 0 25px;
    //}
  }
  .MuiStepper-root {
    .MuiStep-vertical {
      margin-bottom: 15px;
      .MuiStepConnector-root {
        .MuiStepConnector-line {
          border: none;
        }
      }
      .MuiStepLabel-root {
        display: flex;
        flex-direction: row;
        width: 100%;
        .MuiStepLabel-iconContainer {
          margin-right: 15px;
          .MuiStepIcon-root {
            color: var(--blueColor);
          }
        }
        .MuiStepLabel-labelContainer {
          .MuiStepLabel-label {
            margin-top: 0;
            color: black;
          }
        }
      }
    }
  }
  &.werbung {
    //float: right;
    //margin-top: -45px;
    background: var(--startGreen);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    padding-right: 48.5%;
  }
  &.lightGreen {
    //margin-top: 15px;
    width: 97.1%;
    background: var(--startGreenLight);
    li {
      margin-bottom: 5px;
    }
  }
  &.funktionenHeader {
    font-size: 18px;
    padding-top: 130px;
  }
  &.subpage {
    padding: 0;
    width: 100%;
    img {
      width: 100vw;
    }
    .page {
      width: 1024px;
      margin: 0px auto 0 auto;
      padding: 40px 50px;
      display: flex;
      flex-direction: row;
      .left, .right {
        width: 50%;
      }
      .left {
        float: left;
        h2 {
          color: var(--subpageH2);
          font-size: 22px;
        }
        p {
          line-height: 22px;
        }
      }
      .right {
        border: 1px solid transparent;
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 500px;
        margin-top: -155px;
        align-content: center;
      }
    }
  }
}
.col-15 {
  width: 12.5%;
}
.text-center.col-12 {
  width: 13%;
}
.headerBox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-top: 22px;
  &.second {
    align-items: flex-start;
    padding-top: 20px;
    .buttonsHolder {
      width: 49%;
      padding-left: 20px;
    }
  }
  h1, h2 {
    font-weight: normal;
  }
}
.MuiTabs-root {
  font-family: 'mr-eaves-xl-modern', sans-serif !important;
  .MuiTab-textColorPrimary {
    &.Mui-selected {
      color: var(--darkblue) !important;
    }
  }
  .MuiTabs-indicator {
    background-color: var(--darkblue) !important;
  }
}
.MuiButtonBase-root, .MuiTypography-root {
  font-family: 'mr-eaves-xl-modern', sans-serif !important;
}
.MuiInput-underline {
  &:after {
    border-bottom: 2px solid var(--darkblue) !important;
  }
}
.equimentBoxHeader {
  .openClose {
    .MuiSvgIcon-root {

    }
  }
}
.system-select-button {
  .inactive { display: inline-block; }
  .active { display: none; }
  &:hover {
    .inactive { display: none; }
    .active { display: inline-block; }
  }
}
.header-item-title {
  font-size: 22px;
  font-weight: bold;
  color: var(--color2);
  margin-bottom: 15px;
}
.mouseover-hand {
  cursor: pointer;
  .MuiStepLabel-root.Mui-disabled {
    cursor: inherit;
  }
  .MuiStepIcon-completed {
    color: var(--blueColor) !important;
  }
  &:hover {
    * {
      color: var(--blueColor) !important;
    }
  }
}
.stickyRow {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 10px;
  padding-bottom: 10px;
}
.tableStickyHeader {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 9;
  padding-top: 10px;
  padding-bottom: 10px;
  .tableHeader {
    margin-top: 0;
  }
}
.levelSelectBox .description {
  margin: 45px 20px 20px;
}
.gray-border {
  border: 1px solid transparent; // var(--grau5);
  padding: 0 25px 15px 25px;
  margin-left: -15px;
  margin-right: -15px;
  &.wMargin {
    margin-bottom: 15px;
    margin-top: 0;
    //padding: 0 15px 15px 15px;
  }
  &.last-page {
    margin-top: 45px;
    padding-top: 15px;
    margin-bottom: -30px;
    .header-item-new-up {
      padding-top: 0 !important;
      padding-left: 0 !important;
    }
    .fs-box-white-30-white {
      padding: 15px 0;
      margin-bottom: 0;
    }
    .productList {
      padding: 30px 0;
    }
    .fs-box-white-30 {
      &:last-child {
        margin-bottom: 15px;
      }
    }
  }
}
.SerienSelectBox {
  .MuiSvgIcon-fontSizeSmall {
    font-size: 30px;
    color: var(--captionColor);
  }
}
.composite-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 15px 0;
  position: relative;
  min-height: 260px;
  .compositeImage, .arrow-container, .description { flex-grow: 1; }
  .compositeImage { max-width: 220px; }
  .imageFrame, .imageTaster {
    position: absolute;
    top: 0; left: 0;
  }
  .compositeImageBackground {
    width: 240px; height: 240px;
    position: unset; // top: 25px; left: 25px;
    margin: 30px 30px;
    background: var(--compositeImageBackground);
  }
  .description {
    padding: 0;
    width: 50%;
    position: absolute;
    bottom: 0; right: 0;
    padding-bottom: 23px;
  }
  .arrow-container {
    width: 100%;
    height: 50px;
    position: relative;
    margin-top: 108px;
    text-align: right;
    flex-grow: 1;
    padding: 0 15px;
    min-width: 65px;
    &.textLeft { text-align: left; }
    button {
      cursor: pointer;
      border: 1px solid transparent;
      background: transparent;
      font-size: 30px;
      padding: 10px 15px;
      color: var(--captionColor);
      &.hidden { display: none; }
      &.button {
        &:hover { border: 1px solid transparent; color: var(--captionColor); }
      }
      .MuiSvgIcon-fontSizeSmall { font-size: 30px; margin-top: -5px; }
    }
    .arrow {
      border: solid black;
      border-width: 0 5px 5px 0;
      display: inline-block;
      padding: 3px;
      width: 10px;
      height: 10px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 5px;
      &:hover {
        border-color: var(--color1);
      }
    }
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
.floorBoxHeader {
  display: flex;
  //.col-3 {
  //  display: flex; align-items: center;
  //  .labelBox { padding-left: 20px; }
  //}
  .buttonSelectDesignForRoom {
    position: relative;
    padding-top: 5px;
    font-size: 13px;
    .resetIcon {
      position: absolute;
      left: calc(50% - 105px);
      top: 0px;
      &.right {
        left: unset;
        right: calc(100%);
      }
    }
  }
  .buttonResetDesignForRoom {
    position: relative;
    font-size: 13px;
    padding-top: 5px;
    .resetIcon {
      position: absolute;
      top: 0;
      left: calc(50% - 115px);
    }
  }
}
.equimentSelectComponent .floorWrapper .floorBoxHeader.row {
  .col-3 {
    display: flex; align-items: center;
    .labelBox { padding-left: 20px; }
  }
}
.roomRow {
  .buttonSelectDesignForRoom {
    position: relative;
    padding-top: 5px;
    font-size: 13px;
    .resetIcon {
      position: absolute;
      left: calc(50% - 105px);
      top: 12px;
      &.right {
        left: unset;
        right: calc(0% + 30px);
      }
    }
  }
  .buttonResetDesignForRoom {
    position: relative;
    font-size: 13px;
    .resetIcon {
      position: absolute;
      top: 6px;
      left: calc(50% - 115px);
    }
  }
}
.copy-link-tooltip {
  width: auto;
  color: var(--blueColor);
}
.fs-box-white-30 {
  &.transparent {
    background: transparent;
    margin: 30px 10px 0;
  }
  &.last-page, &.productList {
    padding: 30px 40px;
  }
}
.header-item-new-ul {
  padding: 0 15px;
}
.inClipboardNotice {
  background: var(--blueColor);
  color: var(--weiss);
  padding: 15px 25px;
  position: absolute;
  right: -40px;
  top: -6px;
  font-size: 14px;
  border-radius: 3px;
}
.svgDescription {
  font-weight: bold;
  color: var(--textColor);
  font-size: 16px;
  margin-top: 10px;
}
.equimentBoxHeader-subtitle, .equimentBoxHeader-text{
  padding-left: 0;
  font-size: 14px;
}
.equimentBoxHeader-text {
  margin-top: 0;
  text-align: left;
  color: var(--captionColor);
  font-size: 16px;
}
.inlineFlex {
  .showInline { display: inline-flex; }
  .MuiTabs-root {
    display: inline-flex;
    margin-left: 15px;
    min-height: unset;
    .MuiTab-root {
      min-width: unset;
      min-height: unset;
      padding: 0 10px;
    }
  }
}
.tasterNoTitle {
  .MuiTab-root {
    min-width: 100px;
    padding: 15px 12px;
  }
}
.btn_loading {
  border: 10px solid var(--grau1);
  border-top: 10px solid var(--grau3);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.roomHeader{
  padding: 10px 0 0px 5px;
  font-style: italic;
  font-weight: bold;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  .name { flex-grow: 1;}
  .step5SelectItem {
    font-weight: normal;
    font-style: normal;
    cursor: pointer;
    border: 1px solid var(--grau5);
    padding: 10px;
    margin: 10px;
    text-align: center;
    max-width: 25%;
    &.active{
      color: var(--weiss);
      background-color: var(--color1);
      border-color: var(--grau5);
    }
    :hover :before,
    ::before{
      border: 0 none !important;
    }
    .MuiInput-root {
      .MuiInput-input {
        color: var(--color2);
      }
      .MuiSelect-icon {
        color: var(--color2);
      }
    }
  }
}
.load-project-button-container {
  display: inline-flex;
  float: right;
  margin-top: 28px;
  cursor: pointer;
  &:hover {
    color: var(--color1);
  }
}
.load-project-container {
  display: inline-flex;
  float: right;
  margin-top: 28px;
  background: var(--weiss);
  flex-direction: column;
  padding: 15px;
  max-width: 45%;
  animation: all ease-in-out 0.3s;
  &.noborder {
    border: 1px solid transparent;
    min-width: 35%; text-align: right;
    padding-top: 0; padding-right: 0;
    margin-top: 27px; margin-right: -1px;
    background: transparent;
  }
  p {
    margin: 0;
  }
  .openCloseInputbox {
    cursor: pointer;
    margin-bottom: 15px;
    font-weight: bold;
    &:hover {
      color: var(--color1);
    }
  }
  .inputText {
    text-align: center;
    border: 1px solid var(--grau5);
  }
  &:after {
    display: block;
    clear: both;
  }
  .inputBoxContainer {
    .buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;

    }
  }
}
.artNr {
  white-space: nowrap;
}

/* v2 */
.header-container {
  background: var(--weiss);
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: 0 3px 5px rgba(0,0,0,0.55);
  &.hidden { display: none; }
  .header-fixed {
    width: 1116px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    button {
      flex-grow: 0;
      background: var(--weiss);
      &:hover {
        background: var(--blueColorHover);
      }
      &.active {
        background: var(--blueColor);
        &:hover { background: var(--blueColor); }
      }
    }
    .logo-container {
      flex-grow: 1;
      text-align: center;
      img { height: 24px; }
    }
  }
}
.steps-container {
  background: var(--grauBGSteps);
  position: sticky;
  top: 0px;
  z-index: 10;
  .steps-fixed {
    width: 1116px;
    margin: 0 auto;
    height: 63px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .MuiStepper-root {
      margin-top: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .MuiStep-alternativeLabel {
        min-width: 174px;
        padding: 0;
        position: relative;
        .step-button {
          width: 28px;
          height: 28px;
          border-radius: 30px;
          background: var(--captionColor);
          font-size: 22px;
          text-align: center;
          display: flex;
          align-items: center;
          margin: 0 auto;
          .label {
            color: white !important;
            width: 28px;
            text-align: center;
          }
          .step-tooltip {
            position: absolute;
            width: 174px;
            top: calc(100% + 15px); left: -15px;
            font-size: 16px;
            text-align: center;
            color: var(--blueColor);
            display: none;
            border-radius: 3px;
            border: 1px solid var(--grau1);
            background: white;
            box-shadow: 0 0 5px rgba(0,0,0,0.55);
            padding: 15px;
          }
          &.active, &.completed {
            background: var(--blueColor);
            .label { cursor: pointer; }
          }
          &:hover {
            .step-tooltip { display: block; }
          }
        }
        .MuiStepLabel-alternativeLabel { display: none; }
      }
      .MuiStepLabel-label { font-size: 16px !important; line-height: 1; }
      //.MuiStepLabel-root.MuiStepLabel-alternativeLabel { flex-direction: row; align-items: center; }
      .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
        min-width: 50px;
        .MuiStepLabel-labelContainer {
          position: relative;
          .MuiStepLabel-alternativeLabel {
            position: absolute;
            top: 50px;
            opacity: 0;
            left: -75%;
            text-align: center;
          }
        }
        &:hover {
          .MuiStepLabel-labelContainer {
            .MuiStepLabel-alternativeLabel {
              opacity: 1;
            }
          }
        }
      }
      //.MuiStepConnector-root { display: none; }
      .MuiStepConnector-root {
        //min-width: 100px;
      }
      .MuiTypography-body2 {/*font-size: 16px;*/ margin-top: 0; text-align: left;margin-left: 13px;}
      .MuiStepLabel-label.MuiStepLabel-active, .MuiStepLabel-label.MuiStepLabel-completed { color: var(--blueColor); }
    }
  }
}
.linear-color-change {
  height: 374px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  img {
    //height: 311px; width: auto;
    width: 100%; height: auto;
    position: absolute;
    right: 0; top: 0; left: 0; bottom: 0;
    z-index: 1;
  }
  .bg-color-change {
    width: 1116px; height: 311px;
    background: transparent linear-gradient(90deg, #F2F2F2 0%, #FAFAFA 41%, #FAFAFA00 100%) 0% 0% no-repeat padding-box;
    z-index: 2;
    position: absolute;
    top: 0; left: 0;
  }
  .content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 3;
    height: 100%;
    padding: 30px 55px;
    .content {
      display: flex;
      flex-direction: column;
      .app-title {
        font-size: 70px;
        color: var(--weiss);
        font-weight: 100;
        &.home { color: var(--schwarz); }
      }
      .section-title {
        color: var(--weiss);
        &.home { color: var(--schwarz); }
      }
      .load-project-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        padding: 0;
        min-width: 50%;
        button {
          color: var(--textColor) !important;
          background: var(--weiss) !important;
          &:hover {
            background: var(--blueColorHover) !important;
            color: var(--weiss) !important;
          }
          &.active {
            background: var(--blueColor) !important;
            color: var(--weiss) !important;
            &:hover {
              background: var(--blueColorHover) !important;
            }
          }
          cursor: pointer;
          height: 24px;
          padding: 16px 30px;
          font-size: 16px;
          margin: 0 0 0 15px;
          text-align: center;
          text-transform: inherit;
          width: auto;
          border-radius: 7px;
        }
      }
    }
  }
}
.section-title {
  font-size: 32px;
  color: var(--captionColor);
}
.section-text {
  font-size: 16px;
  color: var(--textColor);
}
.plan-code {
  color: var(--textColor);
  border: 1px solid var(--fieldBorderColor);
  height: 30px;
  font-size: 16px;
  margin-bottom: 0;
  background: var(--weiss);
  padding: 0 5px;
  min-width: 190px;
  &.full-width {
    width: 100%;
  }
}
.info-icon {
  .MuiSvgIcon-root {
    fill: var(--textColor);
  }
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.bb-2 {
  border-bottom: 2px solid var(--grauBG);
}
.pb-15 { padding-bottom: 15px; }
.ptb-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.project-type {
  padding: 0; // 70px;
  .addProjectList { background: transparent; }
  .addProjectListItem {
    margin: 0 5px;
    min-width: calc(25% - 30px);
    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }
}
.padding-container {
  padding: 0 55px;
}
.noBrake {
  white-space: nowrap;
}
.border-bottom {
  border-bottom: 1px solid var(--textColor);
}
.background-grau {
  background: var(--grauBG);
}
.stickyTop126 {
  //top: 126px;
  top: 63px;
}
.roomList { background: var(--weiss); }
.addRoomWrapper, .addFloorWrapper {
  background: var(--grauBG);
  .header {
    padding-left: 0;
    .button {
      color: var(--textColor);
      svg {
        width: 26px;
        height: 26px;
        margin-right: 13px;
        color: var(--blueColor);
      }
    }
  }
}
.addFloorWrapper {
  //border-bottom: 1px solid var(--textColor);
  padding-bottom: 15px;
}
.footer-container {
  .navButtons {
    width: 1116px;
    margin: 0 auto;
    padding-top: 40px;
    position: relative;
    margin-bottom: 59px;
    button {
      padding: 16px 109px;
      border-radius: 8px;
      background: var(--blueColor);
      white-space: nowrap;
      text-wrap: nowrap;
      &.grau {
        background: var(--grau3);
      }
      &:hover {
        background: var(--blueColorHover);
      }
      &.woText {
        position: absolute;
        top: 0;
        margin-top: 0;
        margin-right: 0;
        right: -3px;
        width: auto;
        padding: 0;
        border-radius: 0;
        background: transparent;
        &:hover { background: transparent; }
      }
    }
  }
  .footer {
    background: var(--textColor);
    color: var(--weiss);
    font-size: 20px;
    padding: 20px 0;
    &.hidden { display: none; }
    .footer-box {
      width: 1116px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      div { flex-grow: 1; }
      .footerMenu {
        flex-grow: 0;
        a {
          color: var(--weiss);
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
.svgDescription-step-two {
  color: var(--textColor);
  &.wPadding { padding: 0 10px; }
}
.composite-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 260px;
  margin-top: 20px;
  margin-bottom: 0;
}
.design-info {
  flex-direction: column;
  .info-cat {
    font-size: 16px;
    color: var(--textColor);
  }
  .info-txt {
    font-size: 16px;
    color: var(--textColor);
    font-weight: bold;
    margin-bottom: 22px;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bedienkonzeptTabs {
  .MuiTabs-root {
    margin-left: 15px;
  }
  .MuiTab-root {
    min-width: unset;
    .MuiTab-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      img {
        margin-right: 5px;
      }
    }
  }
}
.pricebox-sticky-bottom {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: var(--weiss);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  margin-top: 30px;
  .padding-container {
    width: 1110px;
    margin: 0 auto;
  }
  .border-bottom {
    border-bottom: 2px solid var(--captionColor);
    padding-bottom: 13px;
    margin-bottom: 13px;
  }
}
.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  .section-title {
    flex-grow: 1;
    &:last-child {
      flex-grow: 0;
      width: 200px;
      text-align: right;
      font-weight: 100;
    }
  }
}
.section-body, .section-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  .section-text {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    &:last-child {
      flex-grow: 0;
      width: 200px;
      font-size: 32px;
      text-align: right;
      font-weight: 100;
    }
  }
}
.section-footer {
  .section-text {
    flex-grow: 1;
    flex-shrink: 0;
    &:last-child {
      flex-grow: 0;
      width: auto;
      min-width: 150px;
      font-size: 32px;
      text-align: right;
      font-weight: 100;
      margin-left: 15px;
    }
    &:first-child { flex-shrink: 1; padding-right: 15px; }
  }
}
.last-page-container {
  max-width: 1116px;
  margin: 0 auto;
  background: var(--grauBG);
  padding-top: 15px;
  margin-top: 15px;
  .step5SelectWrapper {
    margin-bottom: -30px;
  }
  .product-list-opened {
    .groupHeader {
      font-size: 32px;
      text-align: center;
      color: var(--captionColor);
      padding: 41px 0 26px;
    }
    .groupContent {
      background: var(--weiss);
      color: var(--textColor);
      .row {
        align-items: center;
        &.wpadding {
          padding: 8px 25px 5px;
        }
      }
    }
    .tableHeader {
      color: var(--textColor);
      padding-right: 30px;
      padding-bottom: 6px;
      margin-top: 0;
      .col-6, .col-2 {
        text-align: right;
      }
      .col-6 { padding-left: 55px; text-align: left; max-width: 46%; }
      .col-2 {
        &:first-child { text-align: left; }
      }
    }
  }
}
.row.planungscode {
  .col {
    flex-grow: 1;
    &:last-child {
      flex-grow: 0;
      button {
        min-width: 270px;
      }
    }
  }
}
.levelSelectBox {
  .description {
    font-size: 16px;
  }
}
.MuiStepIcon-root {
  width: 28px !important;
  height: 28px !important;
  color: var(--captionColor) !important;
  &.MuiStepIcon-active, &.MuiStep-completed {
    color: var(--blueColor) !important;
  }
}
.MuiStep-root {
  &.MuiStep-completed {
    .MuiStepIcon-completed {
      color: var(--blueColor) !important;
    }
  }
}
.MuiStepIcon-text {
  font-size: 22px !important;
  transform: translateY(3px);
  font-family: "mr-eaves-xl-modern", sans-serif !important;
}
.info-icon {
  position: relative;
  display: inline-flex;
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 12px;
    cursor: pointer;
  }
}
.headerMenuItem {
  display: flex;
  align-items: center;
  line-height: 1;
  .info-icon {
    img {
      top: 14px;
    }
  }
}
.system-select-button {
  &:hover {
    .info-icon {
      img {
        display: none;
        top: 14px;
        &.active {
          display: unset;
        }
      }
    }
  }
}
.section-text {
  padding-top: 12px;
  .info-icon {
    margin-top: -20px;
  }
}
.Mui-checked {
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: var(--blueColor) !important;
    }
  }
}
.w-188 {
  min-width: 188px;
}
.roomList {
  .roomRow {
    .contextBoxHover {
      position: relative;
      .displayInlineBlock {
        position: absolute;
        left: 25px;
        display: inline-flex;
        flex-wrap: nowrap;
      }
    }
  }
}
.optional-products {
  //position: relative;
  //border: 1px solid green;
  margin-top: 30px;

  //&:after {
  //  content: "Optional";
  //  color: green;
  //  position: absolute;
  //  left: 0; top: 0;
  //  font-style: italic;
  //  font-size: 18px;
  //  font-weight: bold;
  //}
}
.project-infos {
  display: flex;
  flex-direction: row;
  width: 1116px;
  margin: 0 auto;
}
.header-fixed {
  &.mobile {
    display: none;
  }
}
.construction-type {
  width: 55%;
  margin: 45px auto 0;
}
.phone-columns {
  display: flex;
  flex-direction: row;
}
.next-button, .previous-button {
  display: none;
}
.phone-w-50 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.floorBoxHeader {
  .phone-w-50 { flex: 5 1; }
}
.onerow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.phoneShow { display: none; }
//.section-text.mobile-align-right, .mobile-beg {
//  align-items: flex-end !important;
//  flex-direction: column;
//}
.section-footer .section-text:last-child, .mobile-beg {
  width: auto !important;
}
.section-text {
  &.title {
    text-align: right;
    font-weight: bold;
    font-size: 32px;
    padding-right: 0;
    margin-top: 10px;
  }
}
.button-without-style {
  padding: 0 !important;
  margin: 0 0 0 0 !important;
  background: transparent !important;
  border: none !important;
  width: auto !important;
  box-shadow: none !important;
  min-width: 32px !important;
  display: inline-flex;
  max-height: 32px;
  .MuiButton-label {
    padding: 0 !important;
  }
  &.absolutePosition { position: absolute; }
}
.MuiButton-root {
  .info-icon {
    img {
      &.hideOnHover { display: flex; margin-top: -12px !important; }
      &.showOnHover { display: none; }
    }
  }
  &:hover {
    .button-without-style { display: inline-flex; }
    .info-icon {
      img {
        &.showOnHover { display: flex; }
        &.hideOnHover { display: none; }
      }
    }
  }
}

/* Responsive */
@media (max-width: 800px) {
  body, html { max-width: 100%; overflow-x: hidden; }
  body { padding-top: 57px; }
  .next-button, .previous-button { display: block; }
  .header-container {
    .header-fixed {
      width: 100%;
      padding: 15px 0;
      &.mobile {
        display: flex; flex-wrap: wrap;
        position: fixed; top: 0; background: rgba(255, 255, 255, 0.8);
        .nav-bar {
          position: absolute;
          left: 15px;
          .nav-toggler {
            color: inherit;
          }
        }
        .nav-body {
          position: absolute;
          left: 0; right: 0; top: 100%;
          background: white;
          box-shadow: 0 5px 5px rgba(0,0,0,0.3);
          padding-left: 10%;
          display: flex;
          flex-direction: column;
          padding-bottom: 30px;
          padding-top: 30px;
          .menu-title { font-weight: bold; margin-bottom: 15px; }
          a {
            text-decoration: none;
            color: inherit;
            margin-bottom: 15px;
          }
          .spacer { height: 15px; }
          &.hidden {
            display: none;
          }
        }
      }
      &.desktop { display: none; }
    }
  }
  .steps-container {
    .steps-fixed {
      width: 100%;
      .MuiStepper-root {
        &.MuiStepper-horizontal { width: 100%; }
        .MuiStep-alternativeLabel {
          min-width: 20%;
        }
      }
    }
  }
  .fs-container {
    width: 100%;
    &.linear-color-change { height: 30vw; }
    .padding-container { padding: 0 15px; &.p40 { padding: 0 15px; } }
    .app-title { font-size: 7vw !important; }
    .section-title {font-size: 20px; display: flex; align-items: center; }
    .construction-type {
      width: 100%;
      display: flex;
      button {
        margin: 0;
        width: 49%;
        &:first-child { margin-right: auto; margin-left: 0; }
        &:last-child {margin-left: auto; margin-right: 0; }
        .MuiButton-label { padding: 6px 6px; width: 100%; white-space: nowrap; }
      }
    }
    .project-type {
      width: 100%;
      padding: 0;
      .addProjectList {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .addProjectListItem {
          //min-width: calc(45% - 0px); width: 50%;
          //margin: 3px; flex-grow: 0;
          min-width: calc(100% - 15px);
          width: 50%;
          margin: 3px;
          flex-grow: 0;
          //&:nth-child(2n) { margin-left: 10px; margin-right: 0; }
          //&:nth-child(2n+1) { margin-left: 0; margin-right: 10px;}
        }
      }
    }
    .SelectConfLevelComponentWrapper {
      flex-direction: column;
      width: 100%;
      .levelSelectBox {
        width: 100%;
        margin: 0 0 15px 0;
        align-items: center;
        padding: 15px 0;
        .starsBox { padding-left: 15px; width: 35%; min-width: 35%; }
        .info-icon { padding-right: 15px; }
        .description { text-align: left !important; }
      }
    }
    .equimentSelectComponent {
      padding: 0 15px;
      .stickyTop126 {
        .tableHeader {
          position: relative;
          .text-center {
            width: 50%; max-width: 50%;
            display: flex;
            align-items: flex-start;
            &.w-188 {
              flex-direction: column;
              align-items: center;
            }
            &.hidden { display: none; }
          }
          .next-button {
            position: absolute;
            right: 15px; top: 5%;
          }
          .previous-button {
            position: absolute;
            left: calc(50% + 15px); top: 5%;
          }
        }
      }
      .floorWrapper { .floorBoxHeader { .text-center {flex-direction: column;}}}
      .floorBoxHeader {
        .col-1, .hidden {display: none;}
        .col-3 {
          width: 50%; min-width: 50%; max-width: 50%; display: flex; flex-direction: row; align-items: center;
          .labelBox { display: inline-flex; align-items: center; }
        }
      }
      .roomRow {
        .hidden { display: none; }
        .col-1 { display: none; }
        .text-center { width: 50%; min-width: 50%; max-width: 50%; }
        .labelBox { display: inline-flex; align-items: center; padding-left: 15px; }
        .dragHandle { display: none; }
        .openClose { display: none; }
      }
      .projectBoxHeader {
        .col-3, .text-center { width: 50%; min-width: 50%; max-width: 50%; }
        .col-1, .hidden { display: none; }
      }
    }
    .SerienSelectBox {
      padding-bottom: 10px !important;
      position: relative;
      margin-bottom: 25px;
      .MuiTabs-root {
        .MuiTab-root {
          min-width: 33%;
          max-width: 33%;
          line-height: normal;
          align-items: flex-start;
          img { width: 80px !important; }
        }
        .MuiTabs-indicator { width: 33% !important; }
      }
      &:after {
        display: block;
        content: "";
        height: 10px;
        width: calc(100% + 30px);
        left: -15px; top: calc(100% + 15px);
        position: absolute;
        background: var(--grauBGSteps);
      }
    }
    .composite-with-rahmen {
      position: relative;
      margin-bottom: 25px;
      min-height: 60vw;
      padding: 0;
      .composite-image-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: -5% 0 0;
        .composite-container {
          padding: 0 !important;
          margin: 0 auto 20px -15px;
        }
      }
      .description {
        width: 100%;
        margin: 0 auto 0 15px;
      }
      &:after {
        display: block;
        content: "";
        height: 10px;
        width: calc(100% + 30px);
        left: -15px; top: calc(100% + 15px);
        position: absolute;
        background: var(--grauBGSteps);
      }
    }
    .bedienkonzeptTabs {
      padding-top: 20px;
      flex-direction: column;
      width: calc(100% - 0px);
      position: relative;
      margin-bottom: 45px;
      padding-bottom: 20px;
      .section-title { width: 100%; padding-left: 0; }
      .onerow {
        width: auto;
        margin: 0 auto;
      }
      &.home {
        margin-bottom: -15px;
      }
      &:after {
        display: block;
        content: "";
        height: 10px;
        width: calc(100% + 30px);
        left: -15px; top: calc(100% + 15px);
        position: absolute;
        background: var(--grauBGSteps);
      }
      &.home {
        &:after {
          display: none;
        }
      }
    }
    .tasterColors {
      .MuiTab-root, .MuiTabs-indicator {
        width: 33.33%; min-width: 33.33%; max-width: 33.33%;
      }
      .MuiTab-root {
        padding: 9px 12px;
        .imageFrame {
          width: 120px !important;
          margin-left: -20px !important;
          margin-top: -20px !important;
        }
      }
    }
    .zuweisung {
      padding: 15px 0 0 !important;
      .section-title, .section-text { padding: 15px 25px 15px 15px; }
      .section-text { margin-bottom: 0 !important; }
      .floorWrapper { padding: 0 !important; }
      .floorBoxHeader {
        background: var(--grauBG);
        padding: 0 10px 15px 10px;
        .col-3 {
          display: flex;
          flex-direction: row;
          align-items: center;
          .displayInlineBlock {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 10px;
          }
        }
      }
      .roomRow {
        padding: 0 40px 0 15px;
        .col-3 {
          width: 50%; min-width: 50%; max-width: 50%;
          &.contextBoxHover {
            display: flex; flex-direction: row; align-items: center; padding-left: 5px;
            .labelBox { display: flex; flex-direction: row; align-items: center; width: 100%; padding-right: 0; }
          }
          .openClose { display: none; }
        }
        .col-2 { width: 20%; min-width: 20%; max-width: 20%; }
        .col-4 {
          width: 30%; min-width: 30%; max-width: 30%;
          display: flex; align-items: center;
          .buttonSelectDesignForRoom {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 20px;
            .resetIcon {
              position: absolute;
              left: 5px;
              top: -5px !important;
              &.right {
                left: 0;
                right: unset;
                top: 15px !important;
              }
            }
          }
        }
      }
    }
    .exportButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      button {
        width: calc(50% - 10px); min-width: calc(50% - 10px); max-width: calc(50% - 10px);
        margin: 5px auto;
        font-size: 14px; line-height: 16px !important;
      }
    }
    .planungscode {
      display: flex;
      width: 100%;
      flex-direction: row;
      .col-6 {
        display: flex; flex-direction: column;
        .section-title { flex-grow: 0; }
        .exportButtons {
          flex-direction: column;
          width: 100%; flex-grow: 0;
          .col { width: 100%; }
          button {
            width: 100%; max-width: 100%; min-width: 100%;
            line-height: 28px; padding: 5px;
          }
        }
        .section-text { flex-grow: 1; display: flex;}
      }
    }
  }
  .noBorder { border: none; }
  .project-infos {
    display: none;
    flex-direction: column;
    width: 100%;
    .fs-container { width: 100% !important; max-width: unset !important; margin: 0 !important; margin-top: 15px !important; }
  }
  .project-equipments {
    .stickyRow {
      .text-center {
        &.w-188 {
          min-width: unset;
        }
      }
      &.stickyTop126 {
        .col-5 { min-width: 50%; width: 50%; }
        .phone-columns {
          position: relative;
          .hidden { display: none; }
          .next-button {
            display: unset;
            position: absolute;
            right: 15px;
            top: calc(50% - 7px);
          }
          .previous-button {
            display: unset;
            position: absolute;
            left: 15px;
            top: calc(50% - 7px);
          }
        }
      }
    }
    .phone-w-50 {
      display: flex;
      flex-direction: row;
      width: 50%;
      overflow: hidden;
      align-items: center;
      .w-188 {
        min-width: 100%;
        &.hidden { display: none; }
      }
    }
    .container {
      .floorWrapper {
        .floorBoxHeader {
        }
        .roomList {
          .roomRow {
            .contextBoxHover {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .footer-container {
    .navButtons {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 40px;
      .MuiButton-root {
        max-width: 94%; width: 94%; padding: 0; margin: -1% 3% 3%;
        &.margin-auto { margin: 15px auto 0; }
      }
    }
    .footer-box {
      padding-top: 15px;
      flex-direction: column;
      width: 100% !important;
      .footerMenu {
        margin: 15px auto;
      }
    }
  }
  .last-page-container {
    .padding-container {
      padding: 0 15px;
      .filterWrapper {
        display: flex; flex-direction: row; align-items: center;
        .step5SelectItem { width: calc(50% - 10px); max-width: calc(50% - 10px); min-width: calc(50% - 10px); padding: 0; }
      }
      .product-list-opened {
        .wpadding { padding-left: 5px !important; padding-right: 5px !important; }
      }
    }
  }
  .pricebox-sticky-bottom {
    margin-top: 30px;
    width: calc(100% - 15px);
    padding-right: 15px;
    .padding-container {
      width: 100%;
      padding: 15px;
      .section-footer {
        .section-text {
          width: 100% !important;
          flex-grow: 0; flex-shrink: 1;
          min-width: unset !important;
          &.mobile-align-left { text-align: left !important; flex-grow: 1; }
          &.mobile-align-right { text-align: right !important; flex-grow: 1; align-items: flex-end; flex-direction: column;}
        }
      }
      .section-text {
        .row { width: calc(100% - 15px) !important; }
      }
    }
    .hideMobile { display: none; }
    .mobileColumn {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
      .section-text {
        width: calc(100% - 30px) !important;
        text-align: right !important;
        position: unset !important;
        margin: 0 0 0 -15px !important;
        div { flex-grow: 1; }
        .mobile-pr5 { padding-right: 5px !important; }
        &.mobile-beg {
          width: calc(100% + 15px) !important;
          display: flex;
          align-items: flex-end;
          flex-direction: column;
        }
      }
    }
  }
  .customOpener {
    color: var(--textColor);
    margin-bottom: 15px;
    a {
      color: inherit; display: flex; flex-direction: row; align-items: center; text-decoration: none; width: 100%;
      div { text-align: center; flex-grow: 1; padding: 0 15px;}
    }
  }
  .phoneNoShow { display: none !important; }
  .phoneNoPadding { padding-left: 5px !important; }
  .phoneShow { display: flex; }
  .phoneHide { display: none; }
  .phone-row {
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 30px;
    width: 100%;
    &.justOne { grid-template-columns: 100%; }
  }
  .button { &.customPlan {display: none; }}
  .ppb {margin-bottom: 0 !important;}
  /* Joerg */
  .info-icon {
    width: 25px;
    height: 15px;
    top: -7px;
  }
  .noBrake { img { display: none; } }
  .labeltext {
    display: inline-block;
    vertical-align: bottom;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: unset;
    line-height: 16px;
  }
  .labelBox {
    .dragHandle { display: none; }
  }
  .displayInlineBlock {
    display: inline-flex;
    padding-left: 10px !important;
  }
  .phone-rows {
    flex-direction: column;
  }
  .phone-first-column-big {
    .col-6 {
      width: 20%;
      &:first-child { width: 80%}
    }
  }
  .productName { padding: 0 10px !important;}
  .last-page-container .product-list-opened .tableHeader {
    padding-right: 0;
    .col-6 { padding-left: 60px; &:last-child { padding-left: unset; padding-right: 10px;}}
    &.myjung {
      display: none;
    }
  }
  .product-list-opened {
    .groupContent {
      .text-right { padding-right: 10px; }
    }
  }
  .phone-small {
    .step5SelectItem {
      .MuiInput-input, .MuiButton-label { font-size: 12px !important; }
      svg { display: none; }
      .MuiButton-label { line-height: 12px; }
    }
  }
  .phone-plr-10 {
    padding: 0 10px;
    width: 100px;
    word-break: break-word;
    min-width: 100px;
    white-space: normal;
  }
  .phone-talign-left { text-align: left !important; }
  .planungscode {
    .col-6 {
      &:first-child {
        .section-text { padding-right: 10px; }
      }
    }
  }
  .section-title { font-size: 20px !important; }
  .section-footer .section-text:last-child,
  .section-body .section-text:last-child { font-size: 20px; }
  .phone-tooltip {
    height: 32px;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  .composite-container .arrow-container { min-width: unset; padding: 0; }
  .fs-container .composite-with-rahmen .composite-image-container .composite-container {
    margin: 0 auto 20px 0;
  }
  .customOpener {
    background: var(--blue); //var(--blueColor);
    color: var(--weiss);
    padding: 5px;
    &.opened {
      background: var(--blueColor);
    }
  }
  .info-icon {
    height: 32px;
    position: unset !important;
    margin: 0 !important;
    img {
      position: unset !important;
      margin: 0 !important;
    }
  }
  .equimentBoxHeader .equimentBoxHeader-title {
    display: flex; align-items: center;
  }
  .section-text {
    padding-top: 12px;
    display: flex;
    align-items: center;
  }
  /* Joerg end */
  /* Markus */
  .phone-right {
    .step-tooltip {
      left: unset !important;
      right: 0;
    }
  }
  .phone-multiline {
    display: flex; padding-left: 0; padding-right: 0;
    strong { white-space: normal; word-break: break-word; line-height: normal;}
  }
  .fs-container {
    .construction-type button:last-child {
      &.phone-multiline {
        display: flex; padding-left: 0; padding-right: 0;
      }
    }
  }
  .w-188 {
    min-width: 100% !important;
    max-width: 100%;
    .svgDescription-step-two {
      padding: 0 15px;
    }
  }
  [role="tooltip"] {
    right: 38px;
  }
  .composite-image-container {
    min-height: unset;
  }
  .composite-container {
    width: 100% !important;
    padding: 0; margin: 0 !important;
    .compositeImage {
      width: 70% !important;
      min-width: 70% !important;
      max-width: 70% !important;
      .imageTasterAndFrame {
        width: 100%; height: 100%;
        img { width: 100% !important; margin: 0 !important; }
      }
    }
    .arrow-container {
      width: 15% !important;
      max-width: 15% !important;
    }
  }
  .buttonSelectDesignForRoom { line-height: normal; }
  .section-text {
    &.title {
      font-size: 20px;
      margin-top: 0;
      &.mobile-align-left {
        text-align: left;
      }
    }
  }
  /* Markus end */
  .mobile-pricebox {
    padding: 0 15px;
    .section-header .section-title { width: 100%; }
    .button-foerderung {
      color: var(--weiss);
      text-decoration: none;
      margin-left: auto;
      margin-right: 0;
      background: var(--blue);
      padding: 2px 16px;
      font-size: 16px;
      //font-weight: bold;
      &:hover {
        color: inherit;
      }
    }
    .mobileColumn {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
      .growable { flex-grow: 1; }
      .section-text {
        width: 100%;
        display: flex; flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0; margin: 0;
        div { flex-grow: 0; margin-left: auto; }
        span { flex-grow: 0; &.info-icon { margin-right: 15px !important; } }
        .mobile-pr5 { padding-right: 5px !important; }
        &:first-child { padding-top: 15px; }
      }
      .mobile-beg { padding-top: 10px; }
    }
    .section-text {
      &.mobile-align-right { span {width: 100%; text-align: right; } }
    }
  }
  .gray-border.wMargin {
    padding: 0 !important; margin: 0 !important;
    .clearfix { padding: 0 10px !important; }
  }
  .MuiTabScrollButton-root {
    width: 20px !important;
  }
  .SerienSelectBox .MuiSvgIcon-fontSizeSmall { font-size: 1.25rem; }
  .MuiButton-root {
    .info-icon {
      img {
        &.hideOnHover { margin-top: 0 !important; }
      }
    }
  }
  .text-center {
    &.flex-column {
      display: flex;
      flex-direction: column;
      .svgDescription-step-two { flex-grow: 1; }
      .flex-center {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .navButtons.isiframe {
    flex-direction: column !important;
    button {
      width: 94%;
      max-width: unset;
      margin: -1% 3% 3%;
    }
  }
  .linear-color-change {
    .content-container {
      padding: 3%;
    }
  }
}
/* Responsive end */
.btn-language {
  color: var(--blueColor);
  background-color: var(--weiss);
  text-decoration: none;

  &:hover, &.active {
    background-color: var(--blueColor);
    color: var(--weiss);
  }

  cursor: pointer;
  align-content: center;
  height: auto;
  padding: 5px 23px;
  font-size: 16px;
  margin-top: 0;
  margin-right: 15px;
  margin-left: 0;
  text-align: center;
  border-radius: 0;
  text-transform: inherit;
  width: 46%;
  border: 2px solid transparent;
}
html body iframe { pointer-events: none; }

.last-banner {
  .lastbanner {
    padding: 0;
    background-image: linear-gradient(180deg, #002043 10%, #005286);
    display: flex;
    flex-direction: row;
    &.konfig-anfrage {
      background-image: linear-gradient(180deg, #2f6a89 10%, #5a89a3);
    }
  }
  .people { padding: 0; margin: 0; }
  .btn-send {
    padding-left: 30px;
    padding-right: 30px;
    flex-grow: 0;
    min-width: 187px;
    margin-left: 15px;
    svg {
      transform: rotate(90deg);
      rect { fill: transparent; }
      path { fill: var(--blueColor); }
    }
    &:hover {
      background: var(--blueColor);
      svg {
        path { fill: var(--weiss); }
      }
    }
  }
  form { display: flex; flex-direction: column; }
  .form {
    padding: 40px 26% 45px 0;
    display: flex; flex-direction: column; height: 100%;
    background: transparent;
    .section-title, .section-text { color: var(--weiss); }
    .section-text { flex-grow: 1; }
    .form-row {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      input {
        flex-grow: 1;
        background: transparent;
        border-color: transparent;
        border-bottom: 1px solid white;
        padding: 14px 0; color: white;
        &::placeholder { color: rgba(255, 255, 255, 0.55); }
        &:active, &:focus, &:focus-visible { outline: none; }
      }
    }
    .only-mobile { display: none; }
  }
}
.pl45px { padding-left: 25%; }
.justmobilearrow {
  svg { display: none; }
}
@media (min-width: 1200px) {
  .last-banner {
    .banner { width: 1640px; }
    .people { width: 828px; }
  }
}
@media (min-width: 992px) {
  .last-banner {
    .banner { width: 992px; }
    .people { width: 493px; }
  }
}
@media (min-width: 768px) {
  .last-banner {
    .banner { width: 788px; }
    .people { width: 402px; }
  }
}
@media (min-width: 576px) {
  .last-banner {
    .banner { width: 375px; }
    .people { width: 363px; }
  }
}
@media (max-width: 992px) {
  .justmobilearrow {
    svg { display: inherit; transform: rotate(90deg); }
  }
  .row.planungscode {
    flex-direction: column;
    .exportButtons { flex-direction: row !important; }
  }
  .pl45px {
    padding-left: 0;
    margin-top: 45px;
    position: relative;
    &:before {
      content: '';
      background: white;
      position: absolute; top:-30px;left:-15px;right:-15px;
      height: 15px;
    }
    .justmobilearrow {
      text-align: right !important;
      button {
        max-width: 50% !important;
        min-width: 50% !important;
        width: 50% !important;
      }
    }
  }
  .last-banner {
    .bannerimage { width: 100% !important; }
    .lastbanner {
      display: flex;
      flex-direction: column;
      min-height: 0;
      .form {
        padding: 30px 15px;
        background: white;
        .section-text, .section-title { color: var(--textColor); }
        .form-row {
          flex-direction: column;
          width: 100%;
          input, button { width: 100%; min-width: 100%; margin: 0; }
          button { margin-top: 15px; max-width: 33%; margin-left: auto; min-width: unset; padding-left: 0; padding-right: 0; }
          input {
            border-bottom: 1px solid var(--textColor);
            &::placeholder {
              color: transparent;
            }
            color: var(--textColor);
          }
          .only-mobile { display: inherit; width: 100%; }
          .btn-send {
            background: var(--blueColor);
            color: var(--weiss);
            max-width: 50%;
            border-radius: 8px;
            svg { path { fill: var(--weiss); } }
          }
        }
      }
    }
  }
}
.p0 { padding: 0; }
.equipment-with-info {
  position: relative;
  button { position: absolute; top: 5px; }
}
@media (max-width: 300px) {
  .fs-container .project-type .addProjectList .addProjectListItem {
    min-width: calc(100% - 15px);
    width: 50%;
    margin: 3px;
    flex-grow: 0;
  }
  .footer-container.isiframe  .navButtons {
    display: flex; flex-direction: column;
    width: 100%;
    align-items: center;
    button:first-child {display: none;}
  }
  .text-center.flex-column .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .navButtons.isiframe {
    button:nth-child(2n) {display: none}
  }
  .linear-color-change {
    .content-container {
      padding: 3%;
    }
  }
}
.link-jung:hover { color: #406173 !important; }
.Mui-disabled { pointer-events: none; }

.colors-wrapper {
  display: flex; flex-wrap: wrap;
  width: calc(100% - 110px);
  margin: 30px -15px 10px 41px;
  padding: 15px;
  .color {
    width: 16px;
    height: 65px;
    cursor: pointer;
    margin: 4px 0;
    &.active {
      height: 74px;
      margin: 0;
    }
  }
}
@media(max-width: 800px) {
  #root { overflow-x: hidden; }
  .colors-wrapper {
    display: flex; flex-wrap: wrap;
    width: calc(100%);
    margin: 30px 0 10px 4px;
    padding: 15px 0;
    .color {
      width: 17.7px;
      height: 65px;
      cursor: pointer;
      margin: 4px 0;
      &.active {
        height: 74px;
        margin: 0;
      }
    }
  }
  .footer-container {
    .footer {
      .footer-box {
        .footerMenu {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
.noGap {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.floorBoxHeader .col-3 .dragHandle,
.floorBoxHeader .col-3 .editHandle,
.floorBoxHeader .col-3 .deleteHandle {
  display: none;
  width: 0 !important;
  max-width: 0 !important;
}
.floorBoxHeader .col-3 .labeltext {
  padding-right: 0;
}
@media(max-width: 800px) {
  .zuweisung {
    .floorWrapper {
      .floorBoxHeader {
        .col-4 {
          padding: 0;
          max-width: 30%;
          flex-wrap: nowrap;
          display: flex; flex-direction: row;
          align-items: center;
          .labelBox {
            .labeltext {
              font-size: 14px;
            }
          }
          .resetIcon { display: none; }
        }
        .col-3 {
          .labelBox {
            .labeltext {
              font-size: 14px;
            }
          }
        }
      }
      .roomRow {
        .col-4 {
          padding: 0;
          padding-right: 0px;
          overflow-x: hidden;
          .openClose { display: none; }
          .labelBox {
            width: 100%; padding-left: 0 !important;
            .labeltext { width: 100%; text-align: left; padding: 0 !important;  }
            .dragHandle, .editHandle, .deleteHandle { display: none; width: 0; }
          }
        }
        .col-3 {
          max-width: 25%; width: 25%; min-width: 25%;
        }
      }
    }
  }
}
.konfig-anfrage-form {
  display: flex; background: white;
  .left, .right { display: flex; flex-direction: column; width: 50%; padding: 30px; }
  .left { padding-bottom: 0; }
}
.konfig-input {
  border: transparent;
  border-bottom: 1px solid;
  margin-bottom: 30px;
}
.divHidden{
  opacity:0;
  //width:0;
  //height:0;

}
.divNotHidden{
  opacity:1;
  //width:unset;
  //height:unset;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}
.last-banner {
  .konfig-anfrage-form {
    &.checker {
      * {font-weight: 400 !important; color: var(--captionColor) !important;}
    }
  }
}

@media (max-width: 800px) {
  .last-banner {
    .lastbanner.konfig-anfrage {
      .form { padding: 15px !important; max-width: 100% !important; }
    }
    .konfig-anfrage-form {
      flex-direction: column;
      .left, .right {
        width: calc(100% - 20px); padding: 15px;
        strong { max-width: calc(100% - 50px); }
        textarea, input { width: calc(100% - 15px) !important; }
        .konfig-code { max-width: calc(100% - 10px); }
      }
      &.gcaptcha { padding: 15px !important; max-width: calc(100% - 20px); }
      &.checker {
        padding: 15px !important; max-width: calc(100% - 20px);
        * {font-weight: 400 !important; color: var(--captionColor) !important;}
      }
      &.actions {
        padding: 15px !important; max-width: calc(100% - 20px);
        button { min-width: 100% !important;}
      }
    }
  }
}
textarea {font-family: mr-eaves-xl-modern, sans-serif;font-size: 16px !important;}
textarea::placeholder {font-family: Arial !important;}
.grecaptcha-badge { right: 0 !important; }
.SerienSelectBox {
  .MuiTab-labelIcon {
    max-width: 169px !important;
    line-height: 1 !important;
    min-width: 169px !important;
  }
}
.floorBoxHeader {
  .text-center {
    .MuiSwitch-root {
      margin: 0 auto;
    }
  }
}
