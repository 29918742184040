@import "./../../variables.scss";

.editHandle,
.deleteHandle,
.dragHandle {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}

.labelBox {
  padding: 10px 0;
  font-size: 16px;
  color: var(--textColor);
}

.dragActive .labelBox:hover .dragHandle *,
.dragHandle *,
.dragActive .labelBox:hover .deleteHandle *,
.deleteHandle *,
.dragActive .labelBox:hover .editHandle *,
.editHandle * {
  display: none;
  padding: 0 2px;
}

.mouseover {
  &:hover {
    .dragActive .labelBox:hover .dragHandle *,
    .dragHandle *,
    .dragActive .labelBox:hover .deleteHandle *,
    .deleteHandle *,
    .dragActive .labelBox:hover .editHandle *,
    .editHandle * {
      display: inline-block;
    }
  }
}

.labelBox:hover .dragHandle *,
.labelBox:hover .editHandle *,
.labelBox:hover .deleteHandle * {
  display: inline-block;
}

.labeltext{
  display: inline-block;
  vertical-align: bottom;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
